const getTPRefByName = (at, tp, tpt, tradingPlatforms) => {
    if (tradingPlatforms)
      return tradingPlatforms.find(
        (t) => t.software === tp && t.softwareType === tpt && t.accountTypeRefs.includes(at)
      );
    return "";
  };
  
  const getBundleName = ({
    marketdataProviders,
    currentAccount,
    tradingPlatforms,
    marketBundles,
  }) => {
    const tradingPlatformRef = getTPRefByName(
      currentAccount.accountType,
      currentAccount.software,
      currentAccount.softwareType,
      tradingPlatforms
    )?.reference;
    const markets = currentAccount.markets.map((m) => `${m.split("-")[1]}`);

    // Filter market data entitlements based on the trading platform reference
    const relevantEntitlements = marketdataProviders?.filter((entitlement) =>
      entitlement.tradingPlatformRefs.includes(tradingPlatformRef)
    );
  
    // Further filter the entitlements to those whose names appear in the markets list
    const matchedEntitlements = relevantEntitlements?.filter((entitlement) =>
      markets?.includes(entitlement.name)
    );

    // Determine the bundle name based on the matched entitlements
    if (marketBundles) {
      for (const bundle of marketBundles) {
        if (matchedEntitlements?.length > 0) {
          const isAllMatchedEntitlementsInBundle = matchedEntitlements.every((entitlement) =>
            entitlement.bundle.includes(bundle.name)
          );
    
          const totalProvidersInBundle = relevantEntitlements.filter((entitlement) =>
            entitlement.bundle.includes(bundle.name)
          ).length;
    
          if (isAllMatchedEntitlementsInBundle && totalProvidersInBundle === matchedEntitlements.length) {
            return bundle.name; // All matched entitlements are in this bundle
          }
        }
      }
    }
  
    // If no bundle matches all conditions, return "Custom"
    return "Custom";
  };

  export {
    getTPRefByName,
    getBundleName,
  };