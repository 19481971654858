import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Stepper from "./utilities/stepper/Stepper";
import StepperButtons from "./utilities/stepper-buttons/StepperButtons";
import AboutYouStep from "./steps/AboutYou/AboutYou";
import IDVerificationStep from "./steps/IDVerifiaction/IDVerificationStep";
import BusinessDealStep from "./steps/BusinessDeal/BusinessDealStep";
import AgreementStep from "./steps/Agreement/AgreementStep";
import { testMandatoriesFns } from "./utilities/test-mandatories";
import {
  updateFilesAC,
  setDataRequestedAC,
  setToastInfoAC,
} from "../actions-thunks/actions";
import { profileThunks } from "../actions-thunks/profile-thunks";
import { utilityFns } from "./utilities/utility-functions";
import { Spinner } from "react-bootstrap";
import ToastInfoDialog from "./utilities/element/ToastInfoDialog";
import SumSubInstance from "../sumsub";
import AccountSetupStep from "./steps/AccountSetup/AccountSetup";
import { sumsubThunks } from "../actions-thunks/sumsub-thunk";

import "./layout.scss";

const AuthLayout = () => {
  const [step, setStep] = useState(1);
  const [isSumsubForm] = useState(true);
  const [firstRenderAchieved, setFirstRenderAchieved] = useState(false);
  const [toastInfoData, setToastInfoData] = useState();
  const [localState, setLocalState] = useState({
    profile: {},
    rates: {
      tradingplatforms: [],
      countryrates: [],
      bundles: [],
      groups: [],
      countries: [],
    },
    sumsubDocsAreVerified: false,
    files: {},
    volumePayments: [],
    bundleDescription: "Please select a market data bundle",
    tradingPlatformReference: "",
    tradingPlatform: null,
    marketdataproviders: [],
    usedBundles: {},
    usedGroups: {},
  });
  const {
    dataRequested,
    accountIsCreated,
    profile,
    files,
    rates,
    agreementTemplate,
  } = useSelector((state) => state.profile);
  const { toastInfo, isDownloading } = useSelector((state) => state.utility);
  const { sumsubData } = useSelector((state) => state.sumsub);

  const finalStep = isSumsubForm ? 4 : 5;

  const dispatch = useDispatch();
  // requesting data from db
  useEffect(() => {
    if (firstRenderAchieved && files && !!Object.keys(files).length) {
      setLocalState({
        ...localState,
        files,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, firstRenderAchieved]);

  // if submit application was successfull
  useEffect(() => {
    if (accountIsCreated) {
      setStep(finalStep);
      window.scrollTo(0, 0);
    }
  }, [accountIsCreated, finalStep]);

  //setting step
  useEffect(() => {
    if (!firstRenderAchieved) {
      dispatch(profileThunks.getAllMarketData());
      dispatch(profileThunks.getProfileData());
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "login",
        user_id: localState?.profile?._id,
        onboardingid: localState?.profile?._id,
      });
      let lastStep = Steps.find((step) => {
        return (
          step.name.toLowerCase() ===
          localState.profile.status?.toLowerCase()
        );
      })?.step;
      if (
        localState.profile.status === "Application submitted" &&
        lastStep === undefined
      ) {
        setStep(finalStep);
        return;
      }
      if (
        !localState.profile.status &&
        localState.profile.softwareType === "Sim"
      ) {
        setStep(1);
      }
      if (lastStep) setStep(lastStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firstRenderAchieved]);

  // setting localState after received data from db
  useEffect(() => {
    if (
      dataRequested &&
      utilityFns.checkAvailableAllRates(rates) &&
      !!Object.values(agreementTemplate).length &&
      !firstRenderAchieved
    ) {
      setLocalState({
        ...localState,
        agreementTemplate,
        profile,
        files,
        rates,
      });
      dispatch(setDataRequestedAC(false));
      setFirstRenderAchieved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequested, rates, firstRenderAchieved, agreementTemplate, dispatch]);

  //detect sumsub data
  useEffect(() => {
    if (!!sumsubData) {
      console.log("sumsubData", sumsubData)
      setLocalState({
        ...localState,
        profile: { ...localState.profile, ...sumsubData },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumsubData, dispatch]);

  // Proceed to next step
  const nextStep = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep(step - 1);
  };
  const checkForAboutYou = () => {
    const result = testMandatoriesFns.aboutYou(localState.profile);
    if (result === true) {
      dispatch(
        profileThunks.updateProfileData({
          ...localState.profile,
          lastName: localState.profile.lastName.trim(),
          firstName: localState.profile.firstName.trim(),
          middleName: localState.profile.middleName?.trim(),
          pepcomment: localState.profile.pepcomment?.trim(),
          phone: localState.profile.phone.trim(),
          street: localState.profile.street.trim(),
          street2: localState.profile.street2?.trim(),
          city: localState.profile.city.trim(),
        })
      );
      nextStep();
      return true;
    }
    return false;
  };

  const checkForIDVerification = () => {
    const result = testMandatoriesFns.IDVerification(
      localState.profile,
      localState.files
    );
    if (result === true) {
      dispatch(profileThunks.updateProfileData({ ...localState.profile }));
      dispatch(updateFilesAC(localState.files));
      nextStep();
      return true;
    }
    return false;
  };

  const checkForSumsubDocsVerified = () => {
    if (localState.sumsubDocsAreVerified) {
      dispatch(
        sumsubThunks.syncronizeInfoWithBack(
          localState?.profile?.softwareType === "Sim"
        )
      );
      if (!localState?.profile?.softwareType === "Sim") {
        nextStep();
        return true;
      }
    }
    return false;
  };

  const checkForBusinessDeal = () => {
    const result = testMandatoriesFns.businessDeal(
      localState.profile,
      localState.limits
    );
    if (result === true) {
      const dataToUpdate = {
        ...localState.profile,
        agreement: {
          ...localState.profile.agreement,
          marketData: localState.profile.agreement.marketData
        }
      }
      dispatch(
        profileThunks.updateProfileData(dataToUpdate, true)
      );
      nextStep();
      return true;
    }
    return false;
  };

  const checkForAgreement = () => {
    const result = testMandatoriesFns.agreement(localState);
    if (result === true) {
      dispatch(
        profileThunks.submitProfileData(
          localState.profile,
          localState.agreementTemplate
        )
      );
      return true;
    }
    return false;
  };


  const setToastInfo = (title, message, status, type) => {
    const toastInfo = { title, message, status, type };
    dispatch(setToastInfoAC(toastInfo));
  };

  const CustomSteps = [
    {
      step: 1,
      name: "About You",
      id: "about-you",
      componentToRender: (
        <AboutYouStep localState={localState} setLocalState={setLocalState} />
      ),
      nextButton: checkForAboutYou,
    },
    {
      step: 2,
      name: "ID Verification",
      id: "id-verification",
      componentToRender: (
        <IDVerificationStep
          showSpinner={isDownloading}
          localState={localState}
          setLocalState={setLocalState}
          setToastInfo={setToastInfo}
          setToastInfoData={setToastInfoData}
        />
      ),
      nextButton: checkForIDVerification,
    },
    {
      step: 3,
      name: "Business Deal",
      id: "business-deal",
      componentToRender: (
        <BusinessDealStep
          localState={localState}
          setLocalState={setLocalState}
        />
      ),
      nextButton: checkForBusinessDeal,
    },
    {
      step: 4,
      name: "Review & Agreement",
      id: "review-and-agreement",
      componentToRender: (
        <AgreementStep localState={localState} setLocalState={setLocalState} />
      ),
      nextButton: checkForAgreement,
    },
    {
      step: 5,
      name: "Account Setup",
      id: "account-setup",
      componentToRender: <AccountSetupStep />,
    },
  ];

  const SumsubSteps = [
    {
      step: 1,
      id: "sumsub-id-verification",
      name: "ID Verification",
      componentToRender: (
        <SumSubInstance setLocalState={setLocalState} />
      ),
      nextButton: checkForSumsubDocsVerified,
    },
    {
      step: 2,
      name: "Business Deal",
      id: "business-deal",
      componentToRender: (
        <BusinessDealStep
          localState={localState}
          setLocalState={setLocalState}
        />
      ),
      nextButton: checkForBusinessDeal,
    },
    {
      step: 3,
      name: "Review & Agreement",
      id: "review-and-agreement",
      componentToRender: (
        <AgreementStep localState={localState} setLocalState={setLocalState} />
      ),
      nextButton: checkForAgreement,
    },
    {
      step: 4,
      name: "Account Setup",
      id: "account-setup",
      componentToRender: <AccountSetupStep />,
    },
  ];

  const SimulatorSteps = [
    {
      step: 1,
      id: "sumsub-id-verification",
      name: "ID Verification",
      componentToRender: (
        <SumSubInstance setLocalState={setLocalState} />
      ),
      nextButton: checkForSumsubDocsVerified,
    },
  ];

  const isSimulator = localState.profile.softwareType === "Sim";
  const Steps = isSimulator
    ? SimulatorSteps
    : isSumsubForm
    ? SumsubSteps
    : CustomSteps;
  const currentStep = Steps[step - 1];
  const SNextButton = currentStep.nextButton;

  return utilityFns.checkAvailableAllRates(rates) && firstRenderAchieved ? (
    <div className="auth-main-layout">
      <Stepper
        steps={Steps}
        currentStep={currentStep}
        isSumsubForm={isSumsubForm}
      />
      <div className="outlet-block">
        {currentStep?.componentToRender}
        <div>
          <StepperButtons
            currentStep={currentStep}
            localState={localState}
            nextStep={SNextButton ?? nextStep}
            prevStep={prevStep}
            isSumsubForm={isSumsubForm}
          />
        </div>
      </div>
      <ToastInfoDialog
        toastInfo={toastInfo}
        setToastInfo={setToastInfo}
        toastInfoData={toastInfoData}
        setToastInfoData={setToastInfoData}
      />
    </div>
  ) : (
    <Spinner className="spinner" animation="grow" />
  );
};

export default AuthLayout;
