import React from "react";

import Input from "../../utilities/element/Input";
import Dropdown from "../../utilities/element/Dropdown";
import Checkbox from "../../utilities/element/Checkbox";
import DateInput from "../../utilities/element/DateInput";
import Salutations from "../../utilities/assets/salutation.json";
import { utilityFns } from "../../utilities/utility-functions";

import "./about-you.scss";

const AboutYouStep = ({ fromAgreement = false, localState, setLocalState }) => {
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pep") {
      setLocalState({
        ...localState,
        profile: { 
          ...localState.profile,
          pep: { ...localState.profile.pep, [name]: checked },
          pepcomment: ""
        }
      });
      return;
    }
  
    if (name === "citizenship" && !!!localState.profile.address.country) {
      setLocalState({
        ...localState,
        profile: { 
          ...localState.profile,
          personal: { ...localState.profile.personal, [name]: value },
          address: { ...localState.profile.address, country: value }
        }
      });
      return;
    }
  
    if (['firstName', 'lastName', 'middleName', 'title'].includes(name)) {
      setLocalState({
        ...localState,
        profile: {
          ...localState.profile,
          personal: { ...localState.profile.personal, [name]: value }
        }
      });
    } else if (['street', 'street2', 'city', 'postalCode', 'country'].includes(name)) {
      setLocalState({
        ...localState,
        profile: {
          ...localState.profile,
          address: { ...localState.profile.address, [name]: value }
        }
      });
    } else {
      setLocalState({
        ...localState,
        profile: { ...localState.profile, [name]: value }
      });
    }
  };
  

  const { profile } = localState;
  const { personal, address, pep } = profile;
  const countries = localState?.rates?.countries || [];

  const messageForInvalidFields =
    "Use only Latin letters, dots, apostrophes and space between words";

  const messageForInvalidPhone =
    "Use only digits, dashes, and space between characters";

  const checkIfNotValidDateOfBirth =
    (profile.dateofbirth !== undefined &&
      new Date("1903", "01", "01").getTime() - 31 * 86400 * 1000 >
      new Date(profile.dateofbirth).getTime()) ||
    (profile.dateofbirth !== undefined &&
      !(
        new Date().getTime() - (365 * 18 + 5) * 86400 * 1000 >
        new Date(profile.dateofbirth).getTime()
      ));

  return (
    <div className="about-you-container">
      <div>
        {!fromAgreement && <h4 id="aboutyou">About You</h4>}
        <div className="salut-first-middle">
          <div className="salut-first">
            <Dropdown
              name="title"
              label="Title"
              placeholder="Select"
              isgreyed={fromAgreement.toString()}
              value={personal?.title || ""}
              options={Salutations.map((s) => ({
                value: s.value,
                text: s.value,
              }))}
              onChange={handleChange}
              required
            />
            <Input
              name="firstName"
              label="First name"
              placeholder="Required"
              isgreyed={fromAgreement.toString()}
              value={personal?.firstName || ""}
              messagefornotvalid={messageForInvalidFields}
              onChange={handleChange}
              maxLength="40"
              required
              onValidate={utilityFns.validEnglishTextAndNumber}
            />
          </div>
          <Input
            name="middleName"
            label="Middle name"
            isgreyed={fromAgreement.toString()}
            value={personal?.middleName || ""}
            messagefornotvalid={messageForInvalidFields}
            onChange={handleChange}
            maxLength="40"
            required
            onValidate={utilityFns.validEnglishTextAndNumber}
          />
        </div>
        <div className="last-birth">
          <Input
            name="lastName"
            label="Last name"
            placeholder="Required"
            isgreyed={fromAgreement.toString()}
            value={personal?.lastName || ""}
            messagefornotvalid={messageForInvalidFields}
            onChange={handleChange}
            maxLength="40"
            required
            onValidate={utilityFns.validEnglishTextAndNumber}
          />
          <DateInput
            formnotvalid={
              checkIfNotValidDateOfBirth
                ? checkIfNotValidDateOfBirth
                : undefined
            }
            messagefornotvalid="Please select a valid age to proceed with registration."
            label="Date of birth"
            name="dateofbirth"
            isgreyed={fromAgreement.toString()}
            value={profile?.dateofbirth?.split("T")[0] || ""}
            onChange={handleChange}
            max={new Date(new Date().getTime() + 50 * 365 * 86400 * 1000).toLocaleString('sv').split(" ")[0]}
            required
          />
        </div>
        <div className="city">
          <Dropdown
            label="Citizenship"
            name="citizenship"
            isgreyed={fromAgreement.toString()}
            value={personal?.citizenship || ""}
            placeholder="Select country"
            options={countries.map((s) => ({
              value: s.value,
              text: s.text,
            }))}
            onChange={handleChange}
            required
          />
        </div>
        <div className="pep-section">
          <Checkbox
            name="pep"
            placeholder="I am Politically Exposed Person"
            isgreyed={fromAgreement.toString()}
            // tooltip={<TooltipComponent tooltipText={tooltipText} tooltipClassName="tooltip-item"/>}
            value={pep?.pep || false}
            onChange={handleChange}
          />
          <Input
            name="details"
            placeholder="PEP comment"
            disabled={!pep.pep}
            isgreyed={fromAgreement.toString()}
            value={pep?.details || ""}
            onChange={handleChange}
            maxLength="500"
            onValidate={utilityFns.validEnglishText}
          />

        </div>
      </div>
      <hr />
      <div>
        {!fromAgreement && <h4>Contact Information</h4>}
        <div className="phone">
          <Input
            label="Phone Number (Including country code)"
            placeholder="Required"
            name="phone"
            messagefornotvalid={messageForInvalidPhone}
            onValidate={utilityFns.phoneNumber}
            isgreyed={fromAgreement.toString()}
            value={profile?.phone}
            onChange={handleChange}
            maxLength="40"
            required
          />
        </div>
        <div className="street">
          <Input
            label="Street Address"
            placeholder="Required"
            isgreyed={fromAgreement.toString()}
            name="street"
            value={address?.street || ""}
            onChange={handleChange}
            maxLength="255"
            required
          />
          <Input
            label="Street Address (additional)"
            isgreyed={fromAgreement.toString()}
            name="street2"
            value={address?.street2 || ""}
            onChange={handleChange}
            maxLength="255"
            required
          />
        </div>
        <div className="city-zip">
          <Input
            label="City"
            placeholder="Required"
            isgreyed={fromAgreement.toString()}
            name="city"
            value={address?.city || ""}
            messagefornotvalid={messageForInvalidFields}
            onChange={handleChange}
            required
            onValidate={utilityFns.validEnglishText}
            maxLength="40"
          />
          <div className="zip-styles">
            <Input
              label="Zip/Postal code"
              placeholder="Required"
              isgreyed={fromAgreement.toString()}
              name="postalCode"
              value={address?.postalCode || ""}
              onChange={handleChange}
              required
              maxLength="16"
            />
          </div>
        </div>
        <div className="country">
          <Dropdown
            label="Country"
            value={address?.country || ""}
            isgreyed={fromAgreement.toString()}
            name="country"
            placeholder="Select country"
            options={countries.map((s) => ({
              value: s.value,
              text: s.text,
            }))}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default AboutYouStep;
