import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../../utilities/element/Dropdown";
import Checkbox from "../../utilities/element/Checkbox";

import RangeValue from "../../utilities/element/RangeValue";
import { utilityFns } from "../../utilities/utility-functions";
import RateCalculator from "./rates";

import "./business-deal.scss";
import { getBundleName, getTPRefByName } from "./helpers";

const limits = {
  performanceBond: {
    min: 5000,
    default: 5000,
    max: 200000,
  },
  initialBuyingPower: {
    min: 500000,
    default: 500000,
    max: 10000000,
  },
  maximumDailyLoss: {
    min: 700,
    default: 1,
    max: 27000,
  },
  overnightBuyingPower: {
    min: 0,
    default: 20000,
    max: 2000000,
  },
};
const SHARES = "shares";
const PAYOUT = "payout";
const CUSTOM = "Custom";
const BASIC = "Basic";

const BusinessDealStep = ({ localState, setLocalState }) => {
  const { bundleDescription, rates, profile, tradingPlatform, volumePayments } =
    localState;

  const [bundlePacks, setBundlePacks] = useState([]);

  const TPRates = profile?.accountType
    ? rates?.tradingplatforms.filter((tp) =>
        tp.accountTypeRefs.includes(profile.accountType)
      )
    : rates?.tradingplatforms;

  // Helper function to find and update trading platform based on profile
  const updateTradingPlatform = (profile, rates, localState) => {
    const foundTP = rates?.tradingplatforms.find((tp) => {
      if (
        tp.software === profile.software &&
        tp.softwareType === profile.softwareType &&
        tp.accountTypeRefs.includes(profile.accountType)
      ) {
        return true;
      }
      if (
        !profile.software &&
        profile.accountType &&
        profile.accountType === "zimtraexpert" &&
        tp.accountTypeRefs.includes(profile.accountType)
      ) {
        return true;
      }
      return false;
    });

    if (foundTP) {
      localState.tradingPlatform = foundTP;
      localState.profile.software = foundTP.software;
      localState.profile.softwareType = foundTP.softwareType;
    } else {
      localState.tradingPlatform = {};
    }

    return { ...localState };
  };
  // Helper function to update profile information based on account type and calculation rates
  const updateProfileWithRates = (
    accountTypeRef,
    rates,
    localState,
    isAccountTypeChanging
  ) => {
    const currentCR = rates.calculationrates.find(
      (r) => r.accountTypeRef === accountTypeRef
    );

    if (currentCR) {
      let rulesToSet = {};
      if (
        !profile.agreement.rules ||
        !Object.keys(profile.agreement.rules)?.length ||
        isAccountTypeChanging
      ) {
        const { buyingPower, performanceBond, stopLoss, overnightBuyingPower } =
          currentCR;

        rulesToSet = {
          initialBuyingPower: buyingPower.min,
          performanceBond: performanceBond.min,
          maximumDailyLoss: stopLoss.min,
          overnightBuyingPower: overnightBuyingPower.min,
        };
      } else {
        const {
          initialBuyingPower,
          maximumDailyLoss,
          overnightBuyingPower,
          performanceBond,
        } = profile.agreement.rules;

        rulesToSet = {
          initialBuyingPower,
          performanceBond,
          maximumDailyLoss,
          overnightBuyingPower,
        };
      }

      localState.profile.agreement = {
        ...localState.profile.agreement,
        rules: rulesToSet,
      };

      localState.profile.accountType = accountTypeRef;
      localState.profile.marketdataBundle = BASIC;
    }

    return { ...localState };
  };

  const getCurrentMarketBundle = (localState2) => {
    const mdp = rates?.marketdataproviders?.filter((p) => {
      return p.tradingPlatformRefs.includes(
        getTPRefByName(
          profile.accountType,
          profile.software,
          profile.softwareType,
          localState2.rates.tradingplatforms
        )?.reference
      );
    });
    return getBundleName({
      marketdataProviders: mdp,
      currentAccount: localState2.profile,
      tradingPlatforms: rates?.tradingplatforms,
      marketBundles: rates.bundles,
    });
  };

  const updateMarketDataEntitlements = (profile, localState2) => {
    const fromMarketObjects = (marketObjects) => {
      return marketObjects.map((obj) => `${obj.group}-${obj.market}`);
    };
    if (profile.agreement.marketData) {
      const unitedMarkets = fromMarketObjects(profile.agreement.marketData);
      localState2.profile.markets = [...unitedMarkets];
      localState2.profile.marketdataBundle =
        getCurrentMarketBundle(localState2);
      return { ...localState2 };
    }
  };

  const updateMDEntitlementsWIthBackInfo = (profile, localState2) => {
    const toMarketObjects = profile.markets.map((m) => ({
      market: m.split("-")[1],
      group: m.split("-")[0],
    }));
    localState2 = {
      ...localState2,
      profile: {
        ...localState2.profile,
        agreement: {
          ...localState.profile.agreement,
          marketData: toMarketObjects,
        },
      },
    };
    return { ...localState2 };
  };

  useEffect(() => {
    // Get volume payment data
    const vp = rates.countryrates
      .filter((x) => x.country === "*")
      .sort((a, b) => (a.min < b.min ? -1 : a.min > b.min ? 1 : 0));

    // Clone localState
    let localState2 = { ...localState };

    // Update trading platform, profile with rates, and volume payments
    localState2 = updateTradingPlatform(profile, rates, localState2);
    localState2 = updateProfileWithRates(
      profile.accountType,
      rates,
      localState2
    );
    const volumePaymentsData = vp.length > 0 ? vp : [];
    localState2.volumePayments = [...volumePaymentsData];

    // Process functions and accumulate updates
    localState2 = calculateTradingPlatform(localState2);
    localState2 = calculateMarketdata(localState2);
    localState2 = calculateRanges("", localState2);
    localState2 = updateMarketDataEntitlements(profile, localState2);
    setupLimits(localState2.profile);

    // Call setLocalState only once with accumulated updates
    setLocalState(localState2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates]);

  const handleChangeAccountType = (event, accountTypeName) => {
    let localState2 = { ...localState };
    const accountTypeRef = event.target?.value || accountTypeName;

    localState2 = updateProfileWithRates(
      accountTypeRef,
      rates,
      localState2,
      true
    );
    const TP = rates.tradingplatforms
      .filter((tp) =>
        tp.accountTypeRefs.includes(localState2.profile.accountType)
      )
      .sort((a, b) => a.order - b.order);

    if (TP && TP.length === 1) {
      // For zimtraexpert type
      const newTP = { ...TP[0] };
      localState2.tradingPlatform = newTP;
      localState2.profile.software = newTP.software;
      localState2.profile.softwareType = newTP.softwareType;
    } else if (TP && TP.length > 1) {
      localState2.tradingPlatform = {};
    }

    setupLimits(localState2.profile);

    onUpdate(event, "accountType", localState2);
  };

  const handleChange = (event, value, minmax) => {
    const localState2 = { ...localState };
    if (event.target.type === "checkbox") {
      localState2.profile[value] = event.target.checked;
    } else {
      if (minmax) {
        const f = parseFloat(event.target.value);
        const x = Math.max(minmax.min, Math.min(minmax.max, f));
        if (!isNaN(x)) localState2.profile.agreement.rules[value] = x;
      } else {
        localState2.profile.agreement.rules[value] = event.target.value;
      }
    }
    onUpdate(event, value, localState2);
  };

  const handleChangeTradingPlatform = (event) => {
    const localState2 = { ...localState };
    const tpIndex = TPRates.findIndex((tp) => {
      return tp.name === event.target.value;
    });
    localState2.profile.marketdataBundle = BASIC;
    localState2.tradingPlatform = tpIndex !== -1 ? TPRates[tpIndex] : {};
    localState2.profile.software = localState2.tradingPlatform?.software;
    localState2.profile.softwareType =
      localState2.tradingPlatform?.softwareType;

    onUpdate(event, "tradingPlatform", localState2);
  };
  const marketdataproviders = useMemo(() => {
    const tradingPlatformReference = tradingPlatform?.reference || "";
    const result = rates?.marketdataproviders?.filter((p) => {
      return p.tradingPlatformRefs.includes(tradingPlatformReference);
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates, tradingPlatform, localState, profile.accountType]);

  const usedBundles = useMemo(() => {
    const usedBundles = {};
    const usedGroups = {};

    marketdataproviders?.forEach((p) => {
      if (p.group && p.group.length > 0) {
        usedGroups[p.group] = true;
      }
      p.bundle.forEach((b) => {
        if (b && b.length > 0) {
          usedBundles[b] = true;
        }
      });
    });

    return { usedBundles, usedGroups };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketdataproviders, profile.accountType]);

  const calculateMarketdata = (localState2) => {
    if (
      !!Object.keys(usedBundles.usedBundles).length &&
      !usedBundles.usedBundles[localState2.profile.marketdataBundle]
    ) {
      localState2.profile.marketdataBundle = CUSTOM;
    }

    if (localState2.profile.marketdataBundle === CUSTOM) {
      localState2.profile.markets = localState2.profile.markets.filter(
        (mId) => {
          return marketdataproviders.some(
            (m) => utilityFns.getMarketId(m) === mId
          );
        }
      );
    } else {
      const tradingPlatformRef = localState2.tradingPlatform?.reference;
      const marketdataprovidersLocal = rates?.marketdataproviders?.filter((p) =>
        p.tradingPlatformRefs.includes(tradingPlatformRef)
      );

      localState2.profile.markets = marketdataprovidersLocal.reduce((a, m) => {
        if (m.bundle.includes(localState2.profile.marketdataBundle)) {
          a.push(utilityFns.getMarketId(m));
        }
        return a;
      }, []);
      localState2.bundleDescription =
        rates.bundles.find(
          (b) => b.name === localState2.profile.marketdataBundle
        )?.description || "Please select a market data bundle";
    }

    // Return updated localState2
    localState2 = updateMDEntitlementsWIthBackInfo(profile, localState2);
    return { ...localState2 };
  };

  const bundles = useMemo(() => {
    const filteredMarketdataproviders = marketdataproviders.filter(
      (m) => m.group
    );
    const marketdataTotalPrice = rates.bundles
      .map((b) => b.name)
      .reduce((res, c) => {
        res[c] = 0;
        return res;
      }, {});
    for (let i = 0; i < filteredMarketdataproviders.length; i++) {
      const el = filteredMarketdataproviders[i];
      for (let j = 0; j < el.bundle.length; j++) {
        const el2 = el.bundle[j];
        marketdataTotalPrice[el2] += el.price;
      }
    }

    const bundles2 = rates.bundles
      .filter((b) => !!usedBundles.usedBundles[b.name])
      .map((b) => ({ ...b, price: marketdataTotalPrice[b.name] }));
    let bundleDescription = "";
    const i = bundles2.findIndex((x) => x.name === profile.marketdataBundle);
    if (i >= 0) {
      bundleDescription = `[${bundles2[i].name}] ${bundles2[i].description}`;
    }
    return { bundles: bundles2, bundleDescription };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketdataproviders, usedBundles, profile.accountType]);

  const calculateTradingPlatform = (state) => {
    if (!state.tradingPlatform) {
      const accountTypeRef = state.profile.accountType;
      const filteredTPs = rates?.tradingplatforms
        .map((tp) => (tp.accountTypeRefs.includes(accountTypeRef) ? tp : null))
        .filter((n) => n);
      state.tradingPlatform = filteredTPs[0];
    }

    // Return updated state
    return { ...state };
  };

  useEffect(() => {
    if (bundles && localState.tradingPlatform && rates) {
      const bundleNamesArray = Object.values(bundles.bundles).map(
        (b) => b.name
      );
      const tradingPlatformRef = localState.tradingPlatform?.reference;
      const marketdataprovidersLocal = rates?.marketdataproviders?.filter(
        (p) => {
          return p.tradingPlatformRefs.includes(tradingPlatformRef);
        }
      );
      const bundlePacks = [];
      bundleNamesArray.forEach((b) => {
        const bundlePack = marketdataprovidersLocal.reduce((a, m) => {
          if (m.bundle.includes(b)) {
            a.push(utilityFns.getMarketId(m));
          }
          return a;
        }, []);
        bundlePacks.push({ bundleName: b, pack: bundlePack });
      });
      setBundlePacks(bundlePacks);
    }
  }, [bundles, localState.tradingPlatform, rates]);

  const onUpdate = (event, value, localState2) => {
    if (
      event.target?.type === "checkbox" &&
      event?.target.name === "overnight"
    ) {
      if (event.target.checked) {
      } else {
        localState2.profile.overnightBuyingPower = 0;
      }
    }
    localState2 = calculateRanges(value, localState2);
    localState2 = calculateMarketdata(localState2);

    setLocalState(localState2);
  };

  const marketAmount = useMemo(() => {
    return marketdataproviders
      .filter((m) => m.group)
      .reduce((a, m) => {
        const marketId = utilityFns.getMarketId(m);
        if (profile.markets?.includes(marketId)) {
          a += m.price;
        }
        return a;
      }, 0);
  }, [profile.markets, marketdataproviders]);

  const calculateRanges = (value, localState2) => {
    const { rules = {} } = localState2.profile.agreement || {};
    const {
      initialBuyingPower = 0,
      overnightBuyingPower = 0,
      maximumDailyLoss = 0,
      performanceBond = 0,
    } = rules;

    const parsedRules = {
      initialBuyingPower: parseInt(initialBuyingPower),
      overnightBuyingPower: parseInt(overnightBuyingPower),
      maximumDailyLoss: parseInt(maximumDailyLoss),
      performanceBond: parseInt(performanceBond),
    };

    let r = {
      overnight: localState2.profile.overnight,
      IBP: parsedRules.initialBuyingPower,
      OBP: parsedRules.overnightBuyingPower,
      MDL: parsedRules.maximumDailyLoss,
      PB: parsedRules.performanceBond,
    };

    switch (value) {
      case "initialBuyingPower":
        RateCalculator.setIBP(r, r.IBP, limits);
        break;
      case "overnightBuyingPower":
        RateCalculator.setOBP(r, r.OBP, limits);
        break;
      case "maximumDailyLoss":
        RateCalculator.setMDL(r, r.MDL, limits);
        break;
      case "performanceBond":
        RateCalculator.setPB(r, r.PB, limits);
        break;
      case "overnight":
        RateCalculator.setOvernight(r, r.PB, limits);
        break;
      default:
        break;
    }

    // Update localState2.profile.agreement.rules
    Object.assign(rules, {
      initialBuyingPower: r.IBP,
      overnightBuyingPower: r.OBP,
      maximumDailyLoss: r.MDL,
      performanceBond: r.PB,
    });

    // Return updated localState2
    return { ...localState2, limits };
  };

  const changeBundle = () => {
    return (event) => {
      const localState2 = { ...localState };
      localState2.profile.marketdataBundle = event.target.value;

      if (event.target.value === CUSTOM) {
        localState2.bundleDescription = "Custom market data bundle";
      }

      onUpdate(event, "", localState2);
    };
  };

  // return market or undefined
  const findMarketByReference = (ref) => {
    const index = marketdataproviders.findIndex((m) => {
      return m.reference === ref;
    });
    return index !== -1 ? marketdataproviders[index] : undefined;
  };

  // support only one level of dependency
  const changeMarket = (marketId, price, dependencyRefs, reference) => {
    return (event) => {
      let localState2 = { ...localState };
      if (event.target.type === "checkbox") {
        if (event.target.checked) {
          if (!localState2.profile.markets?.includes(marketId)) {
            localState2.profile.markets?.push(marketId);
            // check dependencies
            if (dependencyRefs && dependencyRefs.length > 0) {
              dependencyRefs.forEach((ref) => {
                const m = findMarketByReference(ref);
                if (!m) {
                  return;
                }
                const marketId2 = utilityFns.getMarketId(m);
                if (!localState2.profile.markets.includes(marketId2)) {
                  localState2.profile.markets.push(marketId2);
                }
              });
            }
          }
        } else {
          const i = localState2.profile.markets.indexOf(marketId);
          if (i >= 0) {
            localState2.profile.markets.splice(i, 1);
            // turn off reference dependencies
            if (reference) {
              marketdataproviders.forEach((m) => {
                if (m.dependencyRefs && m.dependencyRefs.includes(reference)) {
                  const marketId2 = utilityFns.getMarketId(m);
                  const i = localState2.profile.markets.indexOf(marketId2);
                  if (i >= 0) {
                    localState2.profile.markets.splice(i, 1);
                  }
                }
              });
            }
          }
        }
        const matchedBundle = utilityFns.findMatchingBundle(
          localState2.profile.markets,
          bundlePacks
        );
        if (matchedBundle) {
          localState2.profile.marketdataBundle = matchedBundle;
          localState2.bundleDescription = bundles?.bundles.find(
            (b) => b.name === matchedBundle
          )?.description;
        } else {
          localState2.profile.marketdataBundle = CUSTOM;
          localState2.bundleDescription = "Custom market data bundle";
        }

        onUpdate(event, "", localState2);
      }
    };
  };

  const setupLimits = (profile) => {
    if (!rates || !rates.calculationrates) {
      return;
    }
    const accountTypeRef =
      profile.accountType || rates.accounttypes[0].reference;
    const currentCR = rates.calculationrates.find(
      (r) => r.accountTypeRef === accountTypeRef
    );

    if (!!currentCR) {
      const { buyingPower, performanceBond, stopLoss, overnight } = currentCR;
      const overnightBuyingPower = overnight
        ? currentCR.overnightBuyingPower
        : { min: 0, max: 100000 };
      const PBStep = performanceBond.step;
      const N = (performanceBond.max - performanceBond.min) / PBStep;
      const BPStep = (buyingPower.max - buyingPower.min) / N;
      const SLStep = (stopLoss.max - stopLoss.min) / N;
      const OBPStep = (overnightBuyingPower.max - overnightBuyingPower.min) / N;

      limits.performanceBond = {
        min: parseInt(performanceBond.min),
        default: parseInt(performanceBond.min),
        max: performanceBond.max,
        step: PBStep,
      };
      limits.initialBuyingPower = {
        min: parseInt(buyingPower.min),
        default: parseInt(buyingPower.min),
        max: parseInt(buyingPower.max),
        step: BPStep,
      };
      limits.maximumDailyLoss = {
        min: parseInt(stopLoss.min),
        default: parseInt(stopLoss.min),
        max: parseInt(stopLoss.max),
        step: SLStep,
      };
      limits.overnightBuyingPower = {
        min: parseInt(overnightBuyingPower.min),
        default: parseInt(overnightBuyingPower.min),
        max: parseInt(overnightBuyingPower.max),
        step: OBPStep,
      };
    }
  };

  const generateOvernight = () => {
    setupLimits(profile);
    return [
      <Checkbox
        className="overnight"
        key="overnight"
        name="overnight"
        placeholder="I may hold overnight positions"
        value={profile.overnight}
        onChange={(e) => handleChange(e, "overnight")}
      />,
      <RangeValue
        disabled={!profile.overnight}
        overnight={{ name: "overnight", status: profile.overnight }}
        name="overnightBuyingPower"
        key="overnightBuyingPower"
        placeholder="Overnight Buying Power"
        placeholdervalue={utilityFns.money(
          profile.agreement.rules?.overnightBuyingPower || 0,
          0
        )}
        min={limits.overnightBuyingPower.min}
        max={limits.overnightBuyingPower.max}
        step={limits.overnightBuyingPower.step}
        value={
          profile.agreement.rules?.overnightBuyingPower ||
          limits.overnightBuyingPower.min
        }
        minmaxformatter={utilityFns.moneyshort}
        onChange={(e) =>
          handleChange(e, "overnightBuyingPower", limits.overnightBuyingPower)
        }
        required
      />,
    ];
  };

  const generateSliders = () => {
    return (
      <div className="sliders">
        <RangeValue
          placeholder="Refundable Performance Bond"
          name="performanceBond"
          key="performanceBond"
          disabled={!!!profile.accountType}
          placeholdervalue={utilityFns.money(
            profile.agreement.rules?.performanceBond || 0,
            0
          )}
          min={limits.performanceBond.min}
          step={limits.performanceBond.step}
          max={limits.performanceBond.max}
          minmaxformatter={utilityFns.moneyshort}
          value={
            profile.agreement.rules?.performanceBond ||
            limits.performanceBond.min
          }
          onChange={(e) =>
            handleChange(e, "performanceBond", limits.performanceBond)
          }
          required
        />
        <RangeValue
          placeholder="Day Buying Power"
          name="initialBuyingPower"
          key="initialBuyingPower"
          disabled={!!!profile.accountType}
          placeholdervalue={utilityFns.money(
            profile.agreement.rules?.initialBuyingPower || 0,
            0
          )}
          min={limits.initialBuyingPower.min}
          step={limits.initialBuyingPower.step}
          max={limits.initialBuyingPower.max}
          minmaxformatter={utilityFns.moneyshort}
          value={
            profile.agreement.rules?.initialBuyingPower ||
            limits.initialBuyingPower.min
          }
          onChange={(e) =>
            handleChange(e, "initialBuyingPower", limits.initialBuyingPower)
          }
          required
        />
        <RangeValue
          placeholder="Daily Stop Loss"
          name="maximumDailyLoss"
          key="maximumDailyLoss"
          disabled={!!!profile.accountType}
          placeholdervalue={utilityFns.money(
            profile.agreement.rules?.maximumDailyLoss || 0,
            0
          )}
          min={limits.maximumDailyLoss.min}
          step={limits.maximumDailyLoss.step}
          max={limits.maximumDailyLoss.max}
          minmaxformatter={utilityFns.moneyshort}
          value={
            profile.agreement.rules?.maximumDailyLoss ||
            limits.maximumDailyLoss.min
          }
          onChange={(e) =>
            handleChange(e, "maximumDailyLoss", limits.maximumDailyLoss)
          }
          required
        />
        {(profile.accountType === "zimtraelite" ||
          profile.accountType === "zimtrapro") &&
          generateOvernight()}
      </div>
    );
  };

  const getTiers = (forPayout) => {
    if (volumePayments.length > 0) {
      const filterdVP = volumePayments.filter(
        (vp) => vp.accountTypeRef === profile.accountType
      );
      const tiers = filterdVP.find(
        (i) => i.classifier === (forPayout ? PAYOUT : SHARES)
      ).tiers;
      return tiers;
    }
  };

  const generateTradingPlatform = () => {
    const tradingplatformsmenu = () => {
      return rates.tradingplatforms
        .filter((tp) => tp.accountTypeRefs.includes(profile.accountType))
        .sort((a, b) => a.order - b.order)
        .map((t) => {
          return {
            value: t.name,
            text: t.name,
          };
        });
    };

    return (
      <div className="platform-selector">
        <Dropdown
          noneoption="Trading platform"
          placeholder="Select platform"
          disabled={tradingplatformsmenu()?.length < 2}
          options={tradingplatformsmenu()}
          name="tradingplatform"
          value={tradingPlatform?.name || ""}
          onChange={(e) => handleChangeTradingPlatform(e)}
          required
        />
      </div>
    );
  };

  const generateTradingPlatformText = () => {
    if (profile?.accountType) {
      let foundPrice = null;
      getTiers(false)?.forEach((tier) => {
        const tv = generateTierValue(tier, ["software"]);
        if (tv && tv.value && Number(tv.value.substring(1)) > 0) {
          foundPrice = tv.value;
        }
      });
      if (profile.accountType === "zimtraexpert") {
        return (
          <p className="traders-fee">
            The software fee is{" "}
            <span className="bold">free for the first month</span> and depends
            on traded volume afterward
          </p>
        );
      } else {
        return (
          <p className="traders-fee-centered">
            <span>Monthly Software Fee: &nbsp;</span>
            <strong>{foundPrice}</strong>
          </p>
        );
      }
    }
  };

  const generateMarketdataDependency = () => {
    const ref2name = {};
    marketdataproviders
      .filter((m) => !!m.reference)
      .forEach((m) => {
        ref2name[m.reference] = m.name;
      });

    return marketdataproviders
      .filter((m1) => m1.dependencyRefs && m1.dependencyRefs.length > 0)
      .map((m) => {
        return (
          <p className="marketdata-dependency-item" key={m._id}>
            <span className="marketdata-dependency-item-name">
              &#42; {m.name}
            </span>
            &nbsp;requires&nbsp;
            <span className="marketdata-dependency-item-deps">
              {m.dependencyRefs.map((r) => ref2name[r]).join(",")}
            </span>
          </p>
        );
      });
  };

  const marketdataSubscriptions = useMemo(() => {
    const mds = bundles
      ? bundles.bundles.map((b) => {
          return {
            value: b.name,
            text: `${b.name} ${utilityFns.money(b.price, 2)}/month`,
          };
        })
      : [];
    mds.push({
      value: CUSTOM,
      text: `Custom ${utilityFns.money(marketAmount, 2)}/month`,
      disabled: true,
    });
    return mds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates, marketAmount, bundles, profile.accountType]);

  const generateMarketdataSelector = () => {
    return (
      <div className="marketdata-selector">
        <Dropdown
          noneoption="Marketdata Subscription"
          placeholder="Select market data"
          disabled={
            marketdataSubscriptions?.length < 3 ||
            marketdataproviders?.length <= 1
          }
          options={marketdataSubscriptions}
          value={profile.marketdataBundle || ""}
          onChange={changeBundle()}
        />
      </div>
    );
  };

  const generateMarketdataSubscription = () => {
    const { groups } = rates;

    const groupMaxOrders = groups.reduce((acc, group) => {
      const maxOrder = Math.max(
        ...marketdataproviders
          .filter((x) => x.group === group)
          .map((x) => x.order)
      );
      acc[group] = maxOrder;
      return acc;
    }, {});

    const sortedGroups = groups
      .filter((x) => !!usedBundles.usedGroups[x])
      .sort((a, b) => groupMaxOrders[b] - groupMaxOrders[a]);

    return (
      <div className="marketdata-groups">
        {sortedGroups.map((g) => {
          return (
            <div className="marketdata-group" key={g}>
              <div className="name">{g}</div>
              <div className="marketdata-group-items">
                {marketdataproviders
                  ?.filter((x) => x.group === g)
                  .sort((a, b) => b.order - a.order)
                  .map((m) => {
                    const marketId = utilityFns.getMarketId(m);
                    const v = profile.markets?.includes(marketId);
                    const namesuffix =
                      m.dependencyRefs && m.dependencyRefs.length > 0 ? (
                        <span>{m.name} &#42;</span>
                      ) : (
                        <span>{m.name}</span>
                      );
                    return (
                      <div className="marketdata-item" key={m._id}>
                        <div className="name">
                          <Checkbox
                            disabled={marketdataproviders.length === 1}
                            placeholder={namesuffix}
                            value={v}
                            onChange={changeMarket(
                              marketId,
                              m.price,
                              m.dependencyRefs,
                              m.reference
                            )}
                          />
                        </div>
                        <div className={`price ${v ? "selected" : ""}`}>
                          {utilityFns.money(m.price, 2)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
        <div className="marketdata-dependency-items">
          {generateMarketdataDependency()}
        </div>
      </div>
    );
  };

  const formatValue = (v, formats) => {
    if (formats && formats.length > 0) {
      switch (formats[0]) {
        case "money":
          const digits = formats[1] || 0;
          return utilityFns.money(v, digits);
        default:
      }
    }
    return v;
  };

  const generateTierValue = (t, spec) => {
    let value = null;
    switch (spec[0]) {
      case "commission":
        value = t.commission;
        break;
      case "payout":
        value = `${t.payout * 100.0}%`;
        break;
      case "overnight":
        value = `${t.interest.fed ? "FED+" : ""}${t.interest.percent.toFixed(
          2
        )}%`;
        break;
      case "software":
        const tpPrice = tradingPlatform.price;
        let softwareprice = "platform?";
        if (typeof tpPrice !== "undefined") {
          const calculatedPrice = t.software.base
            ? tpPrice + t.software.change
            : t.software.change;

          const adjustedPrice = Math.max(calculatedPrice, 0);
          softwareprice = `$${adjustedPrice}`;
        }
        value = softwareprice;
        break;
      default:
        value = t[spec[0]];
    }

    return {
      className: spec[1] || spec[0],
      value: formatValue(value, spec.slice(2)),
    };
  };

  const renderLeftTablePart = (forPayout) => {
    const leftValues = () => {
      if (volumePayments.length > 0) {
        const filterdVP = volumePayments.filter(
          (vp) => vp.accountTypeRef === profile.accountType
        );
        const tiers = filterdVP.find(
          (i) => i.classifier === (forPayout ? PAYOUT : SHARES)
        ).tiers;
        const formatMaxValue = (tier) => {
          let returnValue = "∞ ";

          if (tier.minSharePrice) {
            returnValue += `(≥ $${tier.minSharePrice})`;
          } else if (tier.maxSharePrice) {
            returnValue += `(< $${tier.maxSharePrice})`;
          }

          return <span className="grey-color">{returnValue}</span>;
        };

        return (
          <div className="monthly-trading-volumes flex-column">
            {tiers.map((t) => {
              return (
                <span>
                  {utilityFns.moneyshort(t.min, 2, true)} -{" "}
                  {t.max > 0
                    ? utilityFns.moneyshort(t.max, 2, true)
                    : formatMaxValue(t)}
                </span>
              );
            })}
          </div>
        );
      }
    };

    return (
      <div className="flex-column">
        <span className="font-bold">
          Monthly Trading Volumes{" "}
          <span className="grey-color">
            <br />
            (shares)
          </span>
        </span>
        {leftValues()}
      </div>
    );
  };

  const renderRightTablePart = (spec, forPayout) => {
    const rightValues = () => {
      if (volumePayments.length > 0) {
        return getTiers(forPayout).map((t) => {
          const tv = generateTierValue(t, spec);
          return <span className={tv.className}>{tv.value}</span>;
        });
      }
    };
    return (
      <div className="flex-column">
        <span className="font-bold">
          Software Fee{" "}
          <span className="grey-color">
            <br />
            ($)
          </span>
        </span>
        {rightValues()}
      </div>
    );
  };

  const renderScheduleA = () => {
    const formatAccType = (type) => {
      if (type === "zimtrapro") return "Zimtra Pro";
      if (type === "zimtraelite") return "Zimtra Elite";
      if (type === "zimtraexpert") return "Zimtra Expert";
    };
    return (
      <div className="schedule-a">
        <span className="font-bold text-left l-h4">SUMMARY</span>
        <div className="flex-between">
          <span>Account Type:</span>
          <span className="font-bold">
            {formatAccType(profile.accountType)}
          </span>
        </div>
        <div className="flex-between">
          <span>Refundable Performance Bond:</span>
          <span className="font-bold">
            {formatValue(profile.agreement.rules?.performanceBond, [
              "money",
              "2",
            ])}
          </span>
        </div>
        <div className="flex-between">
          <span>Day Buying Power:</span>
          <span className="font-bold">
            {formatValue(profile.agreement.rules?.initialBuyingPower, [
              "money",
              "2",
            ])}
          </span>
        </div>
        <div className="flex-between">
          <span>Daily Stop Loss:</span>
          <span className="font-bold">
            {formatValue(profile.agreement.rules?.maximumDailyLoss, [
              "money",
              "2",
            ])}
          </span>
        </div>
        <div className="flex-between">
          <span>Approved Overnight Buying Power:</span>
          <span className="font-bold">
            {formatValue(profile.agreement.rules?.overnightBuyingPower, [
              "money",
              "2",
            ])}
          </span>
        </div>
        <div className="flex-between">
          <span>Trading platform:</span>
          <span className="font-bold">{localState.tradingPlatform?.name}</span>
        </div>
        <div className="flex-between">
          <span>Market data subscription option:</span>
          <span className="font-bold">{profile.marketdataBundle}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="business-deal-wrapper">
      <h4 id="businessdeal">Business Deal</h4>
      <p className="header-text">
        Please use the following sliders to determine your capital and risk
        settings requirements.
      </p>
      <hr />
      <div className="account-type-wrapper">
        <h4>Deal Type</h4>
        <Dropdown
          name="accountType"
          placeholder="Select type"
          value={profile.accountType || ""}
          options={
            rates.accounttypes.map((s) => ({
              value: s.reference,
              text: s.name,
            })) || []
          }
          onChange={(e) => handleChangeAccountType(e, "accountType")}
          required
        />
      </div>
      <div>{generateSliders()}</div>
      <hr />
      <h4>Trading Platform</h4>
      <div className="trading-platform-market-data">
        {generateTradingPlatform()}
        {generateTradingPlatformText()}
        {!!volumePayments?.length &&
          tradingPlatform &&
          !!Object.keys(tradingPlatform).length &&
          tradingPlatform?.reference === "das_web_extra" && (
            <div className="flex market-data-table">
              {renderLeftTablePart()}
              {renderRightTablePart(["software"])}
            </div>
          )}
        <hr />
        <h4>Market Data</h4>
        {generateMarketdataSelector()}
        <div className="traders-fee bundle-description">
          {bundleDescription}
        </div>
        <section>{generateMarketdataSubscription()}</section>
        <hr />
        {renderScheduleA()}
        <hr />
      </div>
    </div>
  );
};

export default BusinessDealStep;
