import Cookies from "js-cookie";
import { SumSubAPI } from "../api/sumsub-api";
import {
  LOG_IN_TOKEN,
  PORTAL_TOKEN,
  SET_ACCESS_TOKEN_ERROR,
  SYNC_WITH_BACK_ERROR,
} from "../reducers/types";
import {
  setErrorAC,
  setLoadingAC,
  setAccessTokenAC,
  updateProfileWithSumsubDataAC,
} from "./actions";
import { utilityFns } from "../ui/utilities/utility-functions";

export const sumsubThunks = {
  getAccessTokenTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await SumSubAPI.getAccessToken();
      if (response.data.success) {
        dispatch(setAccessTokenAC(response.data.token));
      }
      dispatch(setErrorAC({ type: SET_ACCESS_TOKEN_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_ACCESS_TOKEN_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  syncronizeInfoWithBack: (isSimulator) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await SumSubAPI.syncBack();
      if (response.data.success) {
        if (isSimulator) {
          const domain = utilityFns.getDomainFromHostname(window?.location.hostname);
          const token = Cookies.get(LOG_IN_TOKEN);
          Cookies.remove(LOG_IN_TOKEN, {
            domain,
            secure: true,
            sameSite: "None",
          });
          Cookies.set(PORTAL_TOKEN, token, {
            expires: 30,
            domain,
            secure: true,
            sameSite: "None",
          });
          utilityFns.redirectToPortal();
        } else {
          const newProfile = response.data.application;
          const { address, personal, pep } = newProfile;
          const neededData = {
            personal: {
              title: personal.title,
              firstName: personal.firstName,
              middleName: personal.middleName || null,
              lastName: personal.lastName,
              citizenship: personal.citizenship,
            },
            pep: {
              pep: pep.pep,
              pepcomment: pep.pepcomment || null,
            },
            address: {
              street: address.street,
              street2: address.street2,
              city: address.city,
              postalCode: address.postalCode,
              country: address.country,
              state: address.state,
            },
            dateofbirth: newProfile.dateofbirth,
            phone: newProfile.phone,
          };
          dispatch(updateProfileWithSumsubDataAC(neededData));
        }
      }
      dispatch(setErrorAC({ type: SYNC_WITH_BACK_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SYNC_WITH_BACK_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
