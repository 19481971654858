import {
  FIRST_LAST_NAME,
  LOG_IN_TOKEN,
  REDIRECT_URL,
} from "../../reducers/types";

const knownEnvironments = ['dev', 'cloud-stage']; // List of known environments
const knownTwoLevelTLDs = ['at-hub.com'];

const getBaseDomain = (hostname) => {
  const parts = hostname.split('.');
  const numParts = parts.length;

  if (hostname.includes('localhost')) {
    return 'localhost';
  }

  let tld = '';
  if (numParts >= 2) {
    tld = `${parts[numParts - 2]}.${parts[numParts - 1]}`;
  }

  if (knownTwoLevelTLDs.includes(tld) && numParts >= 3) {
    // Base domain is the last three parts
    return parts.slice(-3).join('.');
  }
  // Base domain is the last two parts
  return parts.slice(-2).join('.');
};

const parseHostname = (hostname) => {
  const parts = hostname.split('.');
  const baseDomain = getBaseDomain(hostname);
  const baseDomainParts = baseDomain.split('.');

  // Calculate index of potential subdomains
  const numParts = parts.length;
  const numBaseDomainParts = baseDomainParts.length;
  let index = numParts - numBaseDomainParts - 1; // Index of the subdomain part(s)

  let environment = '';

  if (index >= 0) {
    const potentialSubdomain = parts[index];
    index--;

    // Check if the potential subdomain is an environment
    if (knownEnvironments.includes(potentialSubdomain)) {
      environment = potentialSubdomain;
    } else if (index >= 0) {
      const potentialEnv = parts[index];
      if (knownEnvironments.includes(potentialEnv)) {
        environment = potentialEnv;
      }
    }
  }

  return { environment, baseDomain };
};

const getProtocol = () => (typeof window !== 'undefined' ? window.location.protocol : 'https:');

const getUrl = (desiredSubdomain) => {
  const protocol = getProtocol();
  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'zimtra.com';

  if (hostname.includes('localhost')) {
    return 'http://localhost:3000';
  }

  const { environment, baseDomain } = parseHostname(hostname);

  let fullSubdomain = '';

  if (environment && desiredSubdomain) {
    // Both environment and desired subdomain are present
    fullSubdomain = `${environment}.${desiredSubdomain}`;
  } else if (environment) {
    // Only environment is present
    fullSubdomain = `${environment}`;
  } else if (desiredSubdomain) {
    // Only desired subdomain is present
    fullSubdomain = `${desiredSubdomain}`;
  } else {
    // Neither environment nor desired subdomain are present
    fullSubdomain = '';
  }

  // Construct the URL
  if (fullSubdomain) {
    return `${protocol}//${fullSubdomain}.${baseDomain}`;
  }
  return `${protocol}//${baseDomain}`;
};

export const utilityFns = {
  validEnglishText: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z \-.']*$/)) ||
      typeof t === "undefined"
    );
  },
  strToBoolean: (s) => {
    s = String(s).toLowerCase();
    return s === "true" || s === "yes" || s === "1";
  },
  validEnglishTextAndNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^[A-Za-z0-9 \-.']*$/)) ||
      typeof t === "undefined"
    );
  },
  validEmail: (v) => {
    return (
      (typeof v === "string" &&
        !!v.match(
          /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
        )) ||
      typeof v === "undefined"
    );
  },
  validatePassword: (t) => {
    return (
      (typeof t === "string" &&
        !!t.match(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,40}$/
        ) &&
        t.slice(0, 1) !== " " &&
        t.slice(-1) !== " ") ||
      typeof t === "undefined"
    );
  },
  phoneNumber: (t) => {
    return (
      (typeof t === "string" && !!t.match(/^(\+[0-9]+)?([0-9\\-]*)$/)) ||
      typeof t === "undefined" ||
      t.length === 0
    );
  },
  validIDNumber: (t) => {
    return typeof t === "string" && !!t.match(/^[A-Za-z0-9.\-#+:]+$/);
  },
  money: (v, frac = 0) => {
    const f = parseFloat(v);
    if (!isNaN(f)) {
      return f.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: frac,
      });
    }
    return v;
  },
  moneyshort: (v, frac = 1, noDollar = false) => {
    return (
      `${noDollar ? "" : "$"}` +
      new Intl.NumberFormat("en-US", {
        maximumFractionDigits: frac,
        notation: "compact",
        compactDisplay: "short",
      }).format(v)
    );
  },
  getMarketId: (m) => {
    return `${m.group}-${m.name}`;
  },
  toFixed: (x, pref, max) => {
    const xmax = x.toFixed(max);
    return xmax.slice(-1) === "0" ? x.toFixed(pref) : xmax;
  },
  emptyString: (v) => {
    return typeof v !== "undefined" && v.trim().length !== 0;
  },
  sortByOrderField: (a, b) => {
    return a.order < b.order ? -1 : a.order === b.order ? 0 : 1;
  },
  showForMeOrRostik: (profile) => {
    return (
      profile.email === "kazak13v@gmail.com" ||
      profile.email === "Stoxro@gmail.com" ||
      profile.email === "r.v.tataryn@gmail.com"
    );
  },
  checkAvailableAllRates: (rates) => {
    return Object.keys(rates).every((r) => rates[r].length > 0);
  },
  getImageFromStore: (imageName, place, year = "2022") => {
    return `${process.env.PUBLIC_URL}/${imageName}`;
  },
  addRemoveScrollEventToBody: (add, _event) => {
    const isMobile = window.innerWidth <= 500;
    const slider = document.getElementById("slide-out-widget-area-bg");
    if (add) {
      document
        .querySelector("body")
        .addEventListener(_event, preventScroll, { passive: false });
      document.querySelector("body").classList.add("hidden-scrollbar");
      if (!isMobile) {
        document
          .querySelector("body")
          .classList.add("no-scrollbar-padding-right");
      }
      if (slider) slider.classList.add("no-scrollbar-set-bg");
    } else {
      document
        .querySelector("body")
        .removeEventListener(_event, preventScroll, { passive: false });
      document.querySelector("body").classList.remove("hidden-scrollbar");
      if (!isMobile) {
        document
          .querySelector("body")
          .classList.remove("no-scrollbar-padding-right");
      }
      if (slider) slider.classList.remove("no-scrollbar-set-bg");
    }
  },
  eraseTokens: () => {
    localStorage.removeItem(REDIRECT_URL)
    localStorage.removeItem(LOG_IN_TOKEN)
    sessionStorage.removeItem(REDIRECT_URL);
    
    localStorage.removeItem(FIRST_LAST_NAME);
  },
  findMatchingBundle: (arr, bundles) => {
    if (!bundles || bundles.length === 0) {
      return false;
    }
    // Helper function to compare two arrays
    const arraysEqualSet = (arr1 = [], arr2 = []) => {
      const sortFn = (a, b) => a.localeCompare(b);
      const sortedArr1 = [...arr1].sort(sortFn);
      const sortedArr2 = [...arr2].sort(sortFn);
      return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
    };
  
    // Iterate through each bundle
    for (const bundle of bundles) {
      if (arraysEqualSet(arr, bundle.pack)) {
        return bundle.bundleName;
      }
    }
    return false;
  },
  getDomainFromHostname: (hostname) => {
    if (hostname.includes('localhost')) {
      return 'localhost';
    }

    const parts = hostname.split('.');
    if (parts.length >= 2) {
      const lastPart = parts[parts.length - 1];
      const secondLastPart = parts[parts.length - 2];
      const sld = `${secondLastPart}.${lastPart}`;

      // List of known two-level TLDs
      const twoLevelTLDs = ['at-hub.com'];

      if (twoLevelTLDs.includes(sld)) {
        // Domain is last 3 parts
        return `.${parts.slice(-3).join('.')}`;
      }
      // Domain is last 2 parts
      return `.${parts.slice(-2).join('.')}`;
    }
    return hostname;
  },
  redirectToLanding: (path) => {
    if (path) {
      window.location.replace(`${getUrl('')}/${path}`);
    } else {
      window.location.replace(getUrl(''));
    }
  },
  getLandingUrl: () => getUrl(''),

  redirectToPortal: () => {
      window.location.replace(getUrl('portal'));
  },
};

const preventScroll = (e) => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};
